.header {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 6rem;
  padding: 15px 80px;
  width: 100%;


  @media screen and (max-width: 768px) {
   padding: 15px 20px;
  }
}

.nav {
  display: flex;
  gap: 1.5rem;
  align-items: center;

  div {
    font-family: Lato;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    text-align: left;
  }

  
  @media screen and (max-width: 768px) {
    display: none;
  }
}

.nav-links {
  cursor: pointer;
}

.hero {
  display: flex;
  /* align-items: center; */
  justify-content: start;
  gap: 5rem;
  padding: 100px 100px 100px 80px;
  background-image: center / contain no-repeat url(../assets/Hero-bg.svg);
  background: linear-gradient(116.72deg, #ffeef7 0%, #fff5fa 100%);

  @media screen and (max-width: 1120px) {
    width: 350px;
  }
  @media screen and (max-width: 768px) {
    padding: 100px 20px;
    width: 100vw;
  }
}

.hero-left {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  max-width: 540px;

  @media screen and (max-width: 1120px) {
    width: 350px;
  }
  @media screen and (max-width: 768px) {
   max-width: 320px;
  }
}
.hero-text {
  font-family: Lato;
  font-size: 60px;
  font-weight: 700;
  line-height: 72px;
  letter-spacing: -0.02em;
  text-align: left;
  color: #101828;


}

.hero-right {
  @media screen and (max-width: 768px) {
    display: none;
    }
}


.solutions {

  @media screen and (max-width: 1120px) {
    width: 350px;
  }
  @media screen and (max-width: 768px) {
   padding: 0px;
   font-size: 24px;
   width: 100vw;
  }
}


@media screen and (max-width: 768px) {
.sk-title {
  font-size: 24px;
}
}

.hero-subtext {
  font-family: Lato;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
  color: #475467;
}

.hero-img {
  display: flex;
  align-items: center;
  justify-content: center;
}

.hero-1,
.hero-2,
.hero-3 {
  position: absolute;
  top: 130px;
  left: 900px;
  z-index: 3;
}

.hero-2 {
  right: -30px;
  left: 940px;
  z-index: 2;
}

.hero-3 {
  left: 780px;
  z-index: 1;
}
a {
  text-decoration: none;
  color: #101828;
}

.sk-color-1 {
  color: #00b9b8;
}

.sk-color-2 {
  color: #f40581;
}

.sk-logo {
  width: 156px;
  height: 35.11px;
  padding: 2px 0px 2px 0px;
  gap: 14.89px;
}

.sk-title {
  font-family: Lato;
  font-size: 48px;
  font-weight: 600;
  line-height: 60px;
  letter-spacing: -0.02em;
  text-align: left;
  color: #101828;
}

.sk-subtitle {
  font-family: Lato;
  font-size: 18px;
  font-weight: 500;
  line-height: 28px;
  text-align: left;
  color: #667085;
}

.left-bottom {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;

  font-family: Lato;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  text-align: left;
  color: #667085;
}

.hero-partners {
  display: flex;
  gap: 1rem;

  @media screen and (max-width: 768px) {
    gap: .5rem;
    }
}

.p--3 {
  padding-bottom: 20px;
}

.benefits,
.matters,
.solutions {
  display: flex;
  flex-direction: column;
  padding: 80px;

  @media screen and (max-width: 768px) {
   padding: 20px;

   .sk-title {
    font-size: 32px;
    line-height: 30px;
   }
    }
}

.solutions-cards {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 30px 0px 20px 0px;
}

.s-card {
  width: 400px;
  height: 320px;
  padding: 32px;
  display: flex;
  flex-direction: column;
  gap: 24px;
  border-radius: 16px;
  margin: 20px;

  border: 1px solid #eaecf0;
}

.s-card-img {
  width: 72px;
  height: 72px;
}

.video-frame {
  width: 1200px;
  height: 400px;
  gap: 0px;
  opacity: 0px;
  align-self: center;
  margin-top: 30px;
  border-radius: 8px;

  @media screen and (max-width: 768px) {
    width: 320px;
    }
}

.sc-title {
  font-family: Lato;
  font-size: 20px;
  font-weight: 700;
  line-height: 30px;
  text-align: left;
  color: #101828;
}

.sc-subtitle {
  color: #475467;
  font-family: Lato;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
}
.card-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 30px;
}

.benefitCard {
  width: 360px;
  height: 420px;
  gap: 30px;
  border-radius: 16px;
  overflow: hidden;
}

.benefit-overlay {
  position: relative;
  width: max-content;
  bottom: 140px;
  height: 138px;
  padding: 20px;
  background: linear-gradient(330.33deg, #101828 14.68%, #475467 98.42%);

  @media screen and (max-width: 768px) {
   
    }
}

.bt {
  font-family: Lato;
  font-size: 20px;
  font-weight: 700;
  line-height: 30px;
  text-align: left;
  color: #fff;
  opacity: 1;
}

.st {
  padding-top: 20px;
  font-family: Lato;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  text-align: left;
  color: #fff;
  opacity: 1;
}

.control {
  display: flex;
  flex-direction: column;
  gap: 4rem;
  align-items: center;
  justify-content: center;
  margin: 50px 0px 0px 0px;
  background: linear-gradient(180deg, rgba(255, 255, 255, 0) 45%, #ffffff 100%);
  background-image: url("../assets/gradient-background.png");

  @media screen and (max-width: 768px) {
   display: none;
    }
}

.control-content {
  max-width: 332px;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.control-div {
  display: flex;
  justify-content: space-between;
  gap: 1rem;
  width: 1000px;
  height: 380px;
  margin-top: 70px;
  padding: 40px 100px 40px 100px;
  border-radius: 24px;
  align-items: center;
  background-image: url("../assets//Control-Background.png");
}

.contact-button {
  width: fit-content;
  height: 40px;
  padding: 8px 16px 8px 16px;
  gap: 8px;
  border-radius: 33px;
  border: 1px;
  background: #d71472;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1.5rem;
  color: #fff;
  cursor: pointer;
}

.bg-green {
  background-color: #08783d;
}
.control-text {
  font-family: Lato;
  font-size: 30px;
  font-weight: 700;
  line-height: 38px;
  text-align: left;
}

.control-subtext {
  font-family: Lato;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
}

.partners {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 60px;
}

.partners > h3 {
  font-family: Lato;
  font-size: 24px;
  font-weight: 600;
  line-height: 32px;
  text-align: center;
  color: #667085;
}
.partners-grid {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  gap: 5rem;
  padding: 30px;
}
.testimonials {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 40px 100px 40px 100px;
  gap: 2rem;

  @media screen and (max-width: 768px) {
    display: none;
    }
}

.testimonials-content {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.testimonial-profile {
  display: flex;
  align-items: center;
  gap: 1.4rem;

  /* Add animation */
  animation: slideIn 1s ease-out forwards;
}

.testimonial-profile-name {
  font-family: Lato;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  text-align: left;
  color: #344054;
}

.prev-next-buttons {
  display: flex;
  padding: 20px;
  gap: 2rem;
  align-items: center;
  justify-content: center;
  div {
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5px 10px 5px 10px;
    background-color: #f9fafb;
  }
}

.testimonial-text {
  /* Add animation */
  animation: slideIn 1s ease-out forwards;
}

.testimonial-profile-email {
  color: #667085;
  font-family: Lato;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
}

.testimonial-card {
  max-width: 700px;
  position: relative;
  left: -95px;
  padding: 34px;
  gap: 16px;
  border-radius: 16px;
  background: #f9fafb;
  display: flex;
  flex-direction: column;
}

/* Keyframes for sliding in from the right */
@keyframes slideIn {
  0% {
    transform: translateX(100%); /* Start outside the screen (right) */
  }
  100% {
    transform: translateX(0); /* End at the default position */
  }
}

.footer {
  padding: 60px;
  background-color: #08783d;
  color: #fff;

  font-family: Lato;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  text-align: left;
  align-items: center;
  justify-content: center;
}

.news-letter {
  width: Hug (1, 173px) px;
  height: Hug (158px) px;
  left: 133px;
  padding: 48px;
  gap: 64px;
  border-radius: 16px;
  background-color: #ecfdf3;

  position: relative;
  top: -100px;
  left: 0px;
}

ul {
  list-style-type: none;
  padding-left: 0;
}

li {
  margin-bottom: 20px;
}

.footer-cont {
}

.footer-info {
}

.footer-end {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.txt-green {
  color: #08783d;
}

.about-hero {
  padding: 150px 100px 50px 100px;
  /* background-image: url("../assets/ab-background.png"); */
  sbackground: linear-gradient(180deg, rgba(255, 255, 255, 0) 45%, #ffffff 100%);

  @media screen and (max-width: 768px) {
   padding: 100px 20px 30px 20px;
    }
}

.ab-content {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.ab-title {
  font-family: Lato;
  font-size: 60px;
  font-weight: 700;
  line-height: 72px;
  letter-spacing: -0.02em;
  text-align: center;
}

.ab-subtitle {
  font-family: Lato;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: center;
  color: #475467;
}

.st-img {
  width: 595px;
  height: 458px;
  gap: 0px;
  border-radius: 20px;

  @media screen and (max-width: 768px) {
    width: 357px;
    height: 274.8px;
    }
}

.story {
  display: flex;

  padding: 100px;
  gap: 3rem;
  justify-content: space-between;
  align-items: center;


  @media screen and (max-width: 768px) {
    padding: 20px;
    width: 100vw;
    flex-direction: column;
    }
}

.story-text {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  max-width: 504px;
}

.ab-tag {
  color: #08783d;
  font-family: Lato;
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  text-align: center;
}

.expertise {
  display: flex;
  flex-direction: column;
  padding: 30px 80px;

  @media screen and (max-width: 768px) {
    padding: 20px 20px;
    }
}

.expertise-grid {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 40px 0px 30px 0px;
}

.expertise-item {
  display: flex;
  gap: 1.5rem;
  padding: 20px;
  align-items: center;
  justify-content: center;

  @media screen and (max-width: 768px) {
   justify-content: flex-start;
    }
}

.ex-item-text {
  font-family: Lato;
  font-size: 18px;
  font-weight: 700;
  line-height: 28px;
  text-align: center;
  color: #101828;
}
.ex-item-subtext {
  font-family: Lato;
  font-size: 12px;
  font-weight: 500;
  line-height: 18px;
  text-align: center;
  color: #344054;
}

.contact-container {
  .left,
  .right {
    width: 50%;
  }

  .left {
    background-image: url("../assets/Shield.png");
    height: 100vh;
    @media screen and (max-width: 1120px) {
      width: 350px;
    }
    @media screen and (max-width: 768px) {
      display: none;
    }
  }

  .right {
    background-color: #fcfcfd;
    padding: 30px 100px;

    @media screen and (max-width: 1120px) {
      width: 100vw
    }
    @media screen and (max-width: 768px) {
     padding: 0px;
    }
  }
  display: flex;
}

.form-button {
  width: 400px;
  height: 40px;
  padding: 8px 16px 8px 16px;
  gap: 8px;
  border-radius: 33px;
  border: 1px;
  background-color: #08783d;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  @media screen and (max-width: 1120px) {
    width: 350px;
  }
  @media screen and (max-width: 768px) {
    width: 300px;
  }
}

.form-cont {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;

  .cas-logo {
    width: 85.93px;
  }
}

.r-form {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.r-label {
  display: flex;
  flex-direction: column;

  font-family: Lato;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  text-align: left;
}
.form-cont {
  margin: 40px;
}

.ft-title {
  font-family: Lato;
  font-size: 24px;
  font-weight: 600;
  line-height: 32px;
  text-align: left;
  color: #101828;
}
.ft-subtitle {
  font-family: Lato;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
  color: #667085;
}

input,
select,
textarea {
  width: 400px;
  height: 40px;
  padding: 10px 14px;
  gap: 8px;
  border-radius: 8px;
  border: 1px solid #667085;
  outline: none;

  
  @media screen and (max-width: 768px) {
    width: 300px;
  }
}

/* Remove the active focus outline */
input:focus,
select:focus,
textarea:focus {
  outline: none;
  border-color: #667085;
}

.contact-text {
  padding: 30px;
  font-size: 36px;

  .sk-title {
    font-size: 36px;
  }

  .sk-subtitle {
    font-size: 18px;
  }
}

.faqs-container {
  max-width: 1008px;
  margin: 0 auto;
  padding: 20px;
  margin-bottom: 80px;
}

.faqs {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.faq-item {
  /* padding: 10px 10px; */
  width: 1008px;
  padding: 16px 10px 16px 10px;
  gap: 24px;
  border-radius: 6px;
  border: 1px solid #eaecf0;
}

.faq-question {
  display: flex;
  justify-content: space-between;
  cursor: pointer;
  div {
    font-family: Lato;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    text-align: left;
  }
}

.faq-answer {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.3s ease;

  font-family: Lato;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
  color: #667085;
}

.faq-answer.open {
  max-height: 200px; /* Adjust max-height depending on your content */
}

.vision-card,
.mission-card {
  width: 552px;
  height: 327px;
  gap: 0px;
  border-radius: 32px;

  @media screen and (max-width: 768px) {
    width: 331.2px;
    height: 196.2px;
    }
}

.vision-card {
  display: flex;
  flex-direction: column;
  padding: 30px;
  align-items: flex-start;
  background-color: #ecfdf3;
  box-shadow: 10px 10px 10px 2px #a6f4c5;
}

.mission-card {
  display: flex;
  flex-direction: column;
  padding: 30px;
  align-items: flex-start;
  background-color: #85cdff;
  box-shadow: 10px 10px 10px 2px #85cdff;
}

.vision-mission {
  padding: 20px 100px;
  display: flex;
  flex-direction: column;
  gap: 2rem;

  @media screen and (max-width: 768px) {
   padding: 20px 20px;

    }
}

.vm-cards {
  display: flex;
  align-items: center;
  justify-content: space-around;

  @media screen and (max-width: 768px) {
   flex-direction: column;
   gap: 2rem;
   margin-bottom: 60px;
    }
}

.vc-img {
  width: 168px;
  position: relative;
  left: 320px;

  @media screen and (max-width: 768px) {
    width: 100.8px;
    left: 200px;
    top: -70px;
    z-index: 0;
    }
}

.vc-tag,
.mc-tag {
  font-family: Lato;
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  text-align: center;
}

.vc-tag {
  color: #08783d;
}

.mc-tag {
  color: #0c6aff;
}


.vc-text, .mc-text {
  max-width: 368px;

  @media screen and (max-width: 768px) {
    max-width: 300px;
    z-index: 1;
    }


}