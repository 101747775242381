.close-newsletter-dialog {
  border-radius: 50%;
  position: relative;
  top: -100px;
  align-self: end;
}

.close-newsletter-dialog:hover {
  cursor: pointer;
}

.newsletter-content {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
}

.newsletter-content-text {
  display: flex;
  flex-direction: column;
  max-width: 430px;
  gap: 25px;
}

.newsletter-actions {
  display: flex;
  flex-direction: column;
  gap: 27px;
}

.newsletter-content-text-title {
  color: var(--Black, #000);
  font-family: Komet;
  font-size: 36px;
  font-style: normal;
  font-weight: 700;
  line-height: 44px;
  letter-spacing: -0.72px;
}

.newsletter-content-text-sub {
  color: var(--Grey-400, #98a2b3);
  font-family: Komet;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 31px;
}

.newsletter-input {
  border-radius: 8px;
  border: 1px solid #d0d5dd;
  background: #fff;
  height: 45px;
  padding: 20px;
}

.newsletter-input:focus {
  outline: none;
}

.newsletter-input::placeholder {
  color: var(--Grey-400, #98a2b3);
  font-family: Komet;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
}

.newsletter-button {
  color: var(--White, #fff);
  font-family: Komet;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  border: none;
  border-radius: 5px;
  height: 45px;
  background: var(--Primary-500, #08783d);
}

.newsletter-button:hover {
  background: var(--Primary-600, #0a7a4a);
}

@media screen and (max-width: 960px) {
}
@media screen and (max-width: 768px) {
  .newsletterImage {
    display: none;
  }
}
@media screen and (max-width: 600px) {
}
@media screen and (max-width: 480px) {
  
}
@media screen and (max-width: 375px) {
}
@media screen and (max-width: 320px) {
}


/* Newsletter Dos  */

/* .close-newsletter-dialog-dos {
  border-radius: 50%;
  position: relative;
  top: -250px;
  right: -450px;
}

.newsletter-content-dos {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1rem;
} */