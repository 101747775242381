.event-image {
    max-width: 350px;
    height: auto;
    padding: 10px;
    border-radius: 18px;
    border: 3px;
}

.event-image-container {
    display: flex;
    align-items: center;
    gap: 1rem;
    overflow: auto;
    -ms-overflow-style: none;
}

.event-image-container::-webkit-scrollbar {
    display: none;
}

.event-image-div {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.loader-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh; /* Full screen height */
  }
  
  .spinner {
    border: 2px solid #f3f3f3; 
    border-top: 3px solid #08783d; 
    border-radius: 50%;
    width: 60px;
    height: 60px;
    animation: spin 2s linear infinite;
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
  