.awards {
  display: flex;
  margin: 44px 0px 44px 120px;
  align-items: center;
  gap: 4rem;
  padding: 20px 0px 20px 20px;

  @media screen and (max-width: 768px) {
    display: flex;
    justify-content: center;
    flex-direction: column;
    gap: 20px;
    margin: 0px;
  }
}

.line {
  width: 28%;
  background: #08783d;
  content: " ";
  position: relative;
  height: 6px;
}

.text-div {
  color: var(--Grey-900, #101828);
  font-family: Komet;
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: 44px; /* 122.222% */
  letter-spacing: -0.72px;

  @media screen and (max-width: 768px) {
    text-wrap: nowrap;
  }
}

.awards-div {
  display: flex;
  gap: 44px;
  width: 100%;
  position: relative;
}

.main-award {
  width: 20%;
  height: auto;
  transform: scale(1.2);
  transition: transform 2s ease-in;
}

.award {
  width: 20%;
  height: auto;
  transition: transform 0.1s ease-in;
}

@media screen and (max-width: 768px) {
  .award,
  .main-award {
    width: 30%;
  }
}
@keyframes slide-left {
  0% {
    transform: translateX(0);
  }
  10% {
    transform: translateX(-10%);
  }
  20% {
    transform: translateX(-20%);
  }
  30% {
    transform: translateX(-30%);
  }
  40% {
    transform: translateX(-40%);
  }
  50% {
    transform: translateX(-50%);
  }
  60% {
    transform: translateX(-60%);
  }
  70% {
    transform: translateX(-70%);
  }
  80% {
    transform: translateX(-80%);
  }
  90% {
    transform: translateX(-90%);
  }
  100% {
    transform: translateX(-100%);
  }
}

.awards-div.animate img {
  transform: scale(1.2);
  animation: slide-left 1s ease-in;
}
